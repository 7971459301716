
import {initializeApp} from "firebase/app"
import { getAuth } from "firebase/auth"
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyC_dnrEkBNEUcnHSNV2zzRdObcXVs0g06E",
    authDomain: "jogharsale.firebaseapp.com",
    projectId: "jogharsale",
    storageBucket: "jogharsale.appspot.com",
    messagingSenderId: "677833871238",
    appId: "1:677833871238:web:b0ba4580101ab30e316607",
    measurementId: "G-L3CWZXBYJ1"
  };

const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);
export const auth = getAuth(app);
export default app;