import React,{useState, useEffect} from "react";
import { db } from "../../firebase.js";
import { doc, getDoc } from "firebase/firestore";
// reactstrap components
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Form,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  CardFooter,
  Button,
  Label,
  CustomInput,
  FormGroup,
} from "reactstrap";

// core components
import { ClipboardCopy } from "../../components/Context/ClipboardCopy";

function EnterAddress() {
  const axios = require("axios").default;

  const [firstFocus, setFirstFocus] = React.useState(false);
  const [lastFocus, setLastFocus] = React.useState(false);
  const [displayTimer, setDisplayTimer] = useState(false);
  const [tokensFound, setTokensFound] = useState(false);
  const [address, setAddress] = useState("");
  const [data, setData] = useState();
  const [futureDate, setFutureDate] = useState("");
  const [offMessage, setOffMessage] = useState("-");

  const FindClaim = async () => {
    setDisplayTimer(false);
    if(address==""){
      setOffMessage("-")
      return;
    }
    // first get stake address
    const url =
      "https://cardano-mainnet.blockfrost.io/api/v0/addresses/" + address;
    let stakeAddress=null;
    await axios({
      method: "get",
      url: url,
      headers: { project_id: "mainnetg2FNKflGYIUAL865vZOysuEtS4WCt3iz" },
      responseType: "json",
    }).then(function (response) {
      stakeAddress = response.data.stake_address;
      console.log(stakeAddress);
    }, function(e) {
      console.log('stake address not found')
      console.log(address)
      stakeAddress=address
  });
    console.log("stakeAddress"+stakeAddress)
    const docSnap = await getDoc(doc(db, "Sale", stakeAddress));
    if (docSnap.exists()) {
      console.log("Document data:", docSnap.data());
      setOffMessage("-")
      setData(docSnap.data())
      setTokensFound(true);
      setDisplayTimer(true);

    } else {
      // doc.data() will be undefined in this case
      console.log("No such document!");
      setOffMessage("No Claimable Bash");
      setTokensFound(false);
      setDisplayTimer(true);
    }
  };

  useEffect(() => {
    // calculate from the data when their next air drop will be.
    if(data && (data.type === 'private' || data.type ==='private2')){
      //set private sale details
      const future1 = 'AUG 15, 2022 15:00:00 UTC';
      const future2 = 'SEP 15, 2022 15:00:00 UTC';
      const future3 = 'OCT 15, 2022 15:00:00 UTC';
      const future4 = 'NOV 15, 2022 15:00:00 UTC';
      const now = new Date();
      Date.parse();
      // if(now < Date.parse(future1)){
      //   setFutureDate(future1);
      //   setDisplayTimer(true);
      // }else if(now < Date.parse(future2)){
      //   setFutureDate(future2);
      //   setDisplayTimer(true);
      // }else if(now < Date.parse(future3)){
      //   setFutureDate(future3);
      //   setDisplayTimer(true);
      // }else if(now < Date.parse(future4)){
      //   setFutureDate(future4);
      // }else{
      //   setDisplayTimer(false);
      // }
      setDisplayTimer(true);
      console.log(displayTimer);
    }
  }, [data]);



  return (
    <>
      <div
        className="section section-components"
        data-background-color="tracker-bg-color"
        style={{
          backgroundImage:
            "url(" + require("assets/img/curved-layer-3-3.png").default + ")",
        }}
      >
        <Container style={{ width: "60%" }}>
          <Row>
            <Col className="ml-auto mr-auto" md="5">
              <h3 className="text-center title">
              JOGHAR Seed Investor Dashboard<br></br>
              </h3>

              <div className="space-50"></div>
            </Col>
          </Row>
          <Row
            style={{
              backgroundColor: "rgba(1,1,1,0.25)",
              borderRadius: "15px",
              width: "75%",
              marginLeft: "12%",
              padding: "20px",
              marginBottom: "20px",
            }}
          >
            <Col className="ml-auto mr-auto">
              <h3 className="text-left title">
                Participate
                <br />
              </h3>
              <p>
              To Participate Use the address below to send ADA from your wallet and purchase $JOG tokens in seed sale.
              <br/>
              Minimum seed sale requirement is 2,500 ADA.
              </p>
              <Card className="card-login card-plain">
                <Form action="" className="form" method="">
                  <CardBody>
                    <Row
                      style={{
                        backgroundColor: "rgba(255,255,255,0.25)",
                        borderRadius: "15px",
                        width: "100%",
                      }}
                    >
                      <Col>
                        <p
                          style={{
                            width: "100%",
                            fontSize: "small",
                            fontWeight: "bold",
                            marginTop: "10px",
                          }}
                        >
                          addr1q8ptffqrsraj30yruvlp4q342rxlm8zhk9ytsx70svhc4mrwc8lgjplarzl4mpmlrhcxkw9umep7z37298sedq0xwuhqwxzguk
                        </p>
                      </Col>
                      <Col>
                        <div style={{ margin: "-20px 0 10px 90%" }}>
                          <ClipboardCopy
                            style={{ width: "25%", height: "25%" }}
                            copyText="addr1q8ptffqrsraj30yruvlp4q342rxlm8zhk9ytsx70svhc4mrwc8lgjplarzl4mpmlrhcxkw9umep7z37298sedq0xwuhqwxzguk"
                          />
                        </div>
                      </Col>
                    </Row>
                    <br/>
                    <Row
                      style={{
                        marginLeft: "8%",
                        backgroundColor: "rgba(0,0,0,0.25)",
                        borderRadius: "15px",
                        width: "80%",
                      }}
                    >

                      <p style={{margin: "10px"}}>
                        <strong style={{color: "red", fontWeight: "bold"}}>Warning: </strong> 
                        <small>Do not send funds from an exchange address</small>
                        </p>
                    </Row>
                  </CardBody>
                  <CardFooter className="text-center"></CardFooter>
                  <div className="pull-left">
                    <h6>
                      <a
                        className="link footer-link"
                        href="https://discord.gg/H2MnAMkWfk"
                      >
                        Need Help? Contact The Joghar Team On Discord
                      </a>
                    </h6>
                  </div>
                </Form>
              </Card>
            </Col>
          </Row>
          <Row
            style={{
              backgroundColor: "rgba(1,1,1,0.25)",
              borderRadius: "15px",
              width: "75%",
              marginLeft: "12%",
              padding: "20px",
              marginBottom: "20px",
            }}
          >
            <Col className="ml-auto mr-auto">
              <h3 className="text-left title">Track Your Tokens</h3>
              <Card className="card-login card-plain">
                <Form action="" className="form" method="">
                  <CardBody>
                    <Row>
                      <div style={{ width: "70%", marginRight: "5%" }}>
                        <InputGroup
                          className={
                            "no-border input-lg" +
                            (firstFocus ? " input-group-focus" : "")
                          }
                        >
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="now-ui-icons business_bank"></i>
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            style={{ height: "55px" }}
                            placeholder="Enter your cardano address..."
                            type="text"
                            onFocus={() => setFirstFocus(true)}
                            onBlur={() => setFirstFocus(false)}
                            value={address}
                            onChange={(e)=>{e.preventDefault(); setAddress(e.target.value)}}
                          ></Input>
                        </InputGroup>
                      </div>
                      <div style={{ width: "25%" }}>
                        <Button
                          block
                          onClick={FindClaim}
                          size="lg"
                          style={{
                            marginTop: "0",
                            backgroundColor: "#57dca8",
                            fontWeight: "900",
                          }}
                        >
                          Track
                        </Button>
                      </div>
                    </Row>
                  </CardBody>
                  <CardFooter className="text-center"></CardFooter>
                  <div className="pull-left">
                    <h6>
                      <a
                        className="link footer-link"
                        href="https://discord.gg/H2MnAMkWfk"
                      >
                        Need Help? Contact The Joghar Team On Discord
                      </a>
                    </h6>
                  </div>
                </Form>
              </Card>
            </Col>
          </Row>
          {displayTimer ? (
            tokensFound ? (
            <Row
              style={{
                backgroundColor: "rgba(1,1,1,0.25)",
                borderRadius: "15px",
                width: "75%",
                marginLeft: "12%",
                paddingBottom: "20px",
              }}
            >
              <Col className="ml-auto mr-auto">
                <h5 className="text-center title">JOG Token Balance</h5>
                <h5 className="text-center title">2000.50</h5>
                <h6 className="text-center title">
                  Tokens Claimable On Dec 01, 2022
                </h6>
              </Col>
            </Row>
            ):(
              <Row
              style={{
                backgroundColor: "rgba(1,1,1,0.25)",
                borderRadius: "15px",
                width: "75%",
                marginLeft: "12%",
                paddingBottom: "20px",
              }}
            >
              <Col className="ml-auto mr-auto">
                <h5 className="text-center title">No Trackable Tokens</h5>
              </Col>
            </Row>
            )

          ) : (
            <div/>
          )}
        </Container>
      </div>
    </>
  );
}

export default EnterAddress;
