import React,{useState} from "react";
import copy from '../../assets/img/copyButton.png'
import check from '../../assets/img/check.png'

export function ClipboardCopy({ copyText }) {
    const [isCopied, setIsCopied] = useState(false);
  
    // This is the function we wrote earlier
    async function copyTextToClipboard(text) {
      if ('clipboard' in navigator) {
        return await navigator.clipboard.writeText(text);
      } else {
        return document.execCommand('copy', true, text);
      }
    }
  
    // onClick handler function for the copy button
    const handleCopyClick = () => {
      // Asynchronously call copyTextToClipboard
      copyTextToClipboard(copyText)
        .then(() => {
          //If successful, update the isCopied state value
          setIsCopied(true);
          // setTimeout(() => {
          //   setIsCopied(false);
          // }, 2500);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  
    return (
      <div>
        {/* Bind our handler function to the onClick button property */}
        <button onClick={handleCopyClick} style={{height: "30px", width: "30px", backgroundColor: "white", borderRadius: "50%"}}>
          <img src={isCopied ? check : copy} style={{width: "100%"}} alt="Copy"/>
        </button>
      </div>
    );
  }
  
  
