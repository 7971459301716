/*eslint-disable*/
import React from "react";
import Logo from "assets/img/JOGHARlogo1.png";

// reactstrap components
import { Button, Container, Row, Col } from "reactstrap";

// core components

function FooterBlackSocial() {
  return (
    <>
      <footer className="footer" data-background-color="footer-bg-color">
        <Container>
          <div className="content">
            <Row>
              <Col md="3">
              <img src={Logo} alt="Logo"/>
              </Col>
              <Col md="3"/>
              <Col md="3">
                <h5>Quick Links</h5>
                <ul className="links-vertical">
                  <li>
                    <a
                      className="text-muted"
                      href="https://www.joghar.com/#about-section"
                    >
                      About
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-muted"
                      href="https://docs.joghar.com/contact"
                    >
                      Contact
                    </a>
                  </li>
                </ul>
              </Col>
              <Col md="3">
                <h5><br/></h5>
                <ul className="links-vertical">
                  <li>
                    <a
                      className="text-muted"
                      href="https://www.joghar.com/#roadmap"
                    >
                      Roadmap
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-muted"
                      href="https://www.joghar.com/#roadmap"
                    >
                      Resources
                    </a>
                  </li>
                </ul>
              </Col>
            
              
            </Row>
          </div>
          <hr></hr>
          <div className="copyright" id="copyright">
            Copyright © {new Date().getFullYear()}, JOGHAR. All Rights Reserved
          </div>
        </Container>
      </footer>
    </>
  );
}

export default FooterBlackSocial;
