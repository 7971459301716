/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";
import Logo from "assets/img/JOGHARlogo1.png";
import './custom.scss';

// reactstrap components
import {
  Button,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavItem,
  Nav,
  Container,
  UncontrolledTooltip,
} from "reactstrap";
import { getParseTreeNode } from "typescript";


function StickyNavbar() {
  const [collapseOpen, setCollapseOpen] = React.useState(false);

  return (
    <>
      {collapseOpen ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setCollapseOpen(false);
          }}
        />
      ) : null}
      <Navbar
        className="fixed-top"
        expand="lg"
        style={{ backgroundColor: "#1e2129" }}
      >
        <Container>
          <div className="navbar-translate">
            <NavbarBrand id="navbar-brand">
              <a href="https://joghar.com">              
              <img src={Logo} alt="Logo" style={{ height: "40px" }} />
              </a>
            </NavbarBrand>
            <UncontrolledTooltip target="navbar-brand">
              JOGHAR - Earn By Moving
            </UncontrolledTooltip>
            <button
              onClick={() => {
                document.documentElement.classList.toggle("nav-open");
                setCollapseOpen(!collapseOpen);
              }}
              aria-expanded={collapseOpen}
              className="navbar-toggler"
            >
              <span className="navbar-toggler-bar top-bar"></span>
              <span className="navbar-toggler-bar middle-bar"></span>
              <span className="navbar-toggler-bar bottom-bar"></span>
            </button>
          </div>
          <Collapse isOpen={collapseOpen} navbar>
            <Nav className="ml-auto" id="ceva" navbar>
              <NavItem
                className="nav-link"
              >
               <a href="https://www.joghar.com/#about-section">ABOUT</a>
              </NavItem>
              <NavItem
                className="nav-link"
              >
               <a href="https://www.joghar.com/#features-section">Features</a>
              </NavItem>{" "}
              <NavItem
                className="nav-link"
              >
               <a href="https://www.joghar.com/#token">$JOG Tokenomics</a>
              </NavItem>{" "}
              <NavItem
                className="nav-link"
              >
              <a href="https://www.joghar.com/#roadmap">Roadmap</a>

              </NavItem>{" "}
              <NavItem
                className="nav-link"
                style={{
                  color: "white",
                  fontSize: "medium",
                  fontWeight: "bold",
                }}
              >
               <a href="https://www.joghar.com/#team">Team</a>

              </NavItem>{" "}
              <NavItem
                className="nav-link"

              >
               <a href="https://www.joghar.com/#faqs">FAQ</a>

              </NavItem>
              <NavItem>
                <Button
                  className="nav-link pbutton"
                  href="https://docs.joghar.com/litepaper"
                >
                  <p className="pbutton1">
                    LITEPAPER
                  </p>
                </Button>
              </NavItem>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default StickyNavbar;
